<template>
  <v-row>
    <v-col cols="12" sm="3">
      <ExploreFleetCardDetailsItem
        id="serial-no"
        @click.native="$emit('copySerial')"
        title="Click to copy serial number"
        label="Serial number"
        labelIcon="mdi-raspberry-pi"
        :value="truck.id"
      />

      <ExploreFleetCardDetailsItem
        label="Last message"
        labelIcon="mdi-calendar"
        :value="truck.time_since_last_message"
        :isRealtime="true"
      />

      <ExploreFleetCardDetailsItem
        label="Online"
        labelIcon="mdi-clock"
        :value="truck.uptime_str"
      />

      <!-- TODO: support for this data in ExploreFleetCardDetailsItem component.
      -->
      <v-row>
        <v-col cols="6">
          <v-icon>mdi-cloud-upload</v-icon> &nbsp;
          <RealtimeDataItem :value="truck.bytes_tx" />
        </v-col>
        <v-col cols="6" class="text-right">
          <v-icon>mdi-cloud-download</v-icon> &nbsp;
          <RealtimeDataItem :value="truck.bytes_rx" />
        </v-col>
      </v-row>
    </v-col>

    <v-divider class="d-none d-sm-inline-flex" vertical></v-divider>

    <v-col cols="12" sm="2">
      <ExploreFleetCardDetailsItem
        label="Phones"
        labelIcon="mdi-cellphone-wireless"
        :value="truck.probes"
        :isRealtime="true"
      />

      <ExploreFleetCardDetailsItem
        label="Access Points"
        labelIcon="mdi-access-point"
        :value="truck.stations"
        :isRealtime="true"
      />

      <ExploreFleetCardDetailsItem
        label="Clients"
        labelIcon="mdi-router-wireless"
        :value="truck.clients"
        :isRealtime="true"
      />

      <ExploreFleetCardDetailsItem
        label="Bluetooth"
        labelIcon="mdi-bluetooth"
        :value="truck.beacons"
        :isRealtime="true"
      />
    </v-col>

    <v-divider class="d-none d-sm-inline-flex" vertical></v-divider>

    <v-col cols="12" sm="2">
      <v-row>
        <v-col cols="12">
          <v-progress-linear
            v-model="truck.cpu"
            height="25"
            color="blue lighten-2"
          >
            <strong>
              <v-icon>mdi-cpu-32-bit</v-icon>
              CPU : {{ truck.cpu }} %
            </strong>
          </v-progress-linear>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-progress-linear
            v-model="truck.memory"
            height="25"
            color="blue lighten-2"
          >
            <strong>
              <v-icon>mdi-memory</v-icon>
              MEM : {{ truck.memory }} %
            </strong>
          </v-progress-linear>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-progress-linear
            v-model="truck.temp"
            height="25"
            color="blue lighten-2"
          >
            <strong>
              <v-icon>mdi-thermometer</v-icon>
              {{ truck.temp }}
            </strong>
          </v-progress-linear>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-progress-linear
            v-model="truck.rssi"
            height="25"
            color="blue lighten-2"
          >
            <strong>
              <v-icon>mdi-signal-3g</v-icon>
              {{ truck.rssi }} %
            </strong>
          </v-progress-linear>
        </v-col>
      </v-row>
    </v-col>

    <v-divider class="d-none d-sm-inline-flex" vertical></v-divider>

    <v-col cols="12" sm="5">
      <ExploreFleetCardDetailsItem
        label="Distance from home"
        labelIcon="mdi-map-marker-outline"
      >
        <strong>
          <RealtimeDataItem :value="truck.dfh | toFixed(2)" />
        </strong>
        KM
        <v-chip
          @click="$emit('chipClick', 'isFarFromHome', truck.isFarFromHome)"
          class="red"
          v-if="truck.isFarFromHome"
          small
          dark
          >far from home</v-chip
        >
      </ExploreFleetCardDetailsItem>

      <ExploreFleetCardDetailsItem label="Speed" labelIcon="mdi-speedometer">
        <strong><RealtimeDataItem :value="truck.speed" /></strong> kmph
      </ExploreFleetCardDetailsItem>

      <!-- the value prop acts as fallback content -->
      <ExploreFleetCardDetailsItem
        label="Travelled"
        labelIcon="mdi-truck-fast-outline"
        value="Hasn't moved since boot"
      >
        <template v-if="truck.distance_travelled">
          <strong
            ><RealtimeDataItem :value="truck.distance_travelled | toFixed(2)"
          /></strong>
          km in
          <strong>{{
            truck.first_seen | moment("from") | removeAgoFromMoment
          }}</strong>
        </template>
      </ExploreFleetCardDetailsItem>

      <ExploreFleetCardDetailsItem
        label="Last moved"
        labelIcon="mdi-map"
        value="Hasn't moved since boot"
      >
        <strong v-if="truck.distance_travelled">{{
          truck.last_movement | moment("from")
        }}</strong>
      </ExploreFleetCardDetailsItem>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "ExploreFleetCardDetails",
  components: {
    RealtimeDataItem: () => import("@/components/RealtimeDataItem"),
    ExploreFleetCardDetailsItem: () =>
      import("@/components/ExploreFleetCardDetailsItem"),
  },
  props: {
    truck: {
      type: Object,
      required: true,
    },
  },
  filters: {
    removeAgoFromMoment(value) {
      return value.replaceAll(" ago", "");
    },
  },
};
</script>

<style scoped>
#serial-no:hover {
  background-color: #eeeeee;
  cursor: pointer;
}
</style>
